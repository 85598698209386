<template>
	<div class="layout-menu-container flex justify-content-between flex-column h-full">
		<div>
			<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
		</div>
		<div v-if="!pageLoading" class="w-full pb-3">
			<Divider/>
			<div style="box-shadow: none">
				<div class="text-lg font-bold text-justify" style="word-wrap: break-word;">{{ agency.name }}</div>
				<Divider />
				<div class="font-medium mb-1">
					<span class="text-600">Package: </span>
					<span class="text-900">{{ agency.package }}</span>
				</div>
				<div class="font-medium mb-1">
					<span class="text-600">Tour limit: </span>
					<span class="text-900">{{ agency.tour_limit }}</span>
					<span class="text-primary text-xs"> /mo</span>
				</div>
				<!-- <div class="block text-700 font-medium mb-1">Package: <span class="text-900 font-medium" style="word-wrap: break-word;">{{ agency.package }}</span></div> -->
				<!-- <div class="block text-700 font-medium">Tour limit: <span class="text-900 font-medium" style="word-wrap: break-word;">{{ agency.tour_limit }}</span><span class="text-primary text-xs"> /mo</span></div> -->
			</div>
		</div>
	</div>
</template>

<script>
import { ref } from '@vue/reactivity';
import AppSubmenu from './AppSubmenu';
import  Axios  from 'axios';

export default {
	props: {
		model: Array
	},
	setup() {
		const agency = ref();
		const pageLoading = ref(true);

		const getManagerData = () =>{
            Axios
            .get("api/operator/agency")
            .then((res) =>{
                pageLoading.value = false;
                if (res.data.response == "success") {
                    agency.value = res.data.data;
                }
                else{
                    console.log(res.data.message);
					pageLoading.value = true;
                }
            })
            .catch((err) =>{
                console.log(err);
                pageLoading.value = true;
            })
        }
        getManagerData();

		return{
			agency,
			pageLoading
		}
	},
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
		onKeyDown(event) {
			const nodeElement = event.target;
			if (event.code === 'Enter' || event.code === 'Space') {
				nodeElement.click();
				event.preventDefault();
			}
		},
		bannerImage() {
			return this.$appState.darkTheme ? 'images/banner-primeblocks-dark.png' : 'images/banner-primeblocks.png';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	},
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>

<style scoped>
.p-card-title {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
}
.p-card-subtitle {
    font-weight: 400;
    margin-bottom: 0.5rem;
    color: rgba(0, 0, 0, 0.6);
}
.p-card-content {
    padding-top: 1rem;
}
.p-divider.p-divider-horizontal {
	margin: .75rem !important;
}
</style>